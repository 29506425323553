import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/": [~6],
		"/account/password": [8,[2]],
		"/account/profile": [~9,[2]],
		"/account/subscription": [~10,[2]],
		"/auth/forgot-password": [11],
		"/auth/login": [~12],
		"/auth/register": [~13],
		"/auth/reset-password": [14],
		"/bejegyzes/[slug]": [~15],
		"/calendar": [16],
		"/csatlakozas": [~17],
		"/kozosseg": [~18],
		"/kozosseg/[type]": [19,[3]],
		"/kozosseg/[type]/advent-calendar-2023": [21,[3]],
		"/kozosseg/[type]/flashcards-game": [~23,[3]],
		"/kozosseg/[type]/flashcards": [22,[3]],
		"/kozosseg/[type]/[slug]": [~20,[3]],
		"/leckek": [~25],
		"/leckek/osszes": [28],
		"/leckek/[playlist]": [26,[4]],
		"/leckek/[playlist]/[slug]": [~27,[4]],
		"/lecke/[slug]": [~24],
		"/stripe/redirect": [29],
		"/upload": [30],
		"/watchlist": [31,[5]],
		"/watchlist/[slug]": [~32,[5]],
		"/[slug]": [~7]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';